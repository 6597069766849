<template>
  <div class="search-item" @click="$emit('click', item)">
    <Icon v-if="selected" name="checkmark" class="absolute left-0 m-1 w-2 h-2 text-green-400"/>
    <Icon v-if="selected" name="close" class="right-0 m-1 absolute w-2 h-2"/>

    <div v-if="item.type === 'COMMUNITY'">
      all <TextHighlighter :text="item.communityName" :query="searchQuery" start-only/> contacts
    </div>

    <div v-else-if="item.type === 'BUILDING'">
      <TextHighlighter :text="item.buildingName" :query="searchQuery" start-only/>
    </div>

    <div v-else-if="item.type === 'UNIT'">
      <TextHighlighter :text="item.unitName" :query="searchQuery" start-only/>,
      building <TextHighlighter :text="item.buildingName" :query="searchQuery" start-only/>
    </div>

    <div v-else-if="item.type === 'PERSON' && backLink" class="flex items-center text-blue-500">
      <Icon name="chevronLeft" class="w-4 h-4 flex-shrink-0 mr-2" />
      <div>
        <div>{{item.firstName}} {{item.lastName}}</div>
        <div>{{item.email}}</div>
      </div>
    </div>

    <div v-else-if="item.type === 'PERSON' && personDetails">
      all units
    </div>

    <div v-else-if="item.type === 'PERSON'">
      <div>
        <TextHighlighter :text="item.firstName" :query="searchQuery" start-only/> <TextHighlighter :text="item.lastName" :query="searchQuery" start-only/>
      </div>
      <div><TextHighlighter :text="item.email" :query="searchQuery" start-only/></div>
      <div class="pt-1">
        <template v-if="item.profiles.length > 1">{{item.profiles.length}} units</template>
        <template v-else>
          unit <TextHighlighter :text="item.profiles[0].unitName" :query="searchQuery" start-only/>,
          building <TextHighlighter :text="item.profiles[0].buildingName" :query="searchQuery" start-only/>
        </template>
      </div>
      <div class="pt-1" v-if="withDetails"><span class="text-blue-500 underline">see all</span></div>
    </div>

    <div v-else-if="item.type === 'PROFILE' && personDetails">
      unit {{item.unitName}}, building {{item.buildingName}}
    </div>

    <div v-else-if="item.type === 'PROFILE'">
      <div>
        <TextHighlighter :text="item.firstName" :query="searchQuery" start-only/> <TextHighlighter :text="item.lastName" :query="searchQuery" start-only/>
      </div>
      <div><TextHighlighter :text="item.email" :query="searchQuery" start-only/></div>
      <div class="pt-1">
        unit <TextHighlighter :text="item.unitName" :query="searchQuery" start-only/>,
        building <TextHighlighter :text="item.buildingName" :query="searchQuery" start-only/>
      </div>
    </div>
  </div>
</template>

<script>
import TextHighlighter from "@/components/ui/TextHighlighter";
import Icon from "@/components/ui/Icon";

export default {
  components: {TextHighlighter, Icon},

  props: {
    item: {
      type: Object,
      required: true,
    },

    backLink: {
      type: Boolean,
      default: false,
    },

    personDetails: {
      type: Boolean,
      default: false,
    },

    searchQuery: {
      type: String,
      default: '',
    },

    selected: {
      type: Boolean,
      default: false,
    },

    withDetails: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
};
</script>

<style scoped>
.search-item {
  @apply px-4 py-2 text-gray-500 cursor-pointer;
}

.search-item:hover {
  @apply bg-highlight-100;
}

.search-item:deep(.highlighter .highlighted) {
  @apply bg-transparent text-black;
}
</style>
