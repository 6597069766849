export const prepareFullQueryModeHighlighter = query => {
    const func = input => {
        const firstOccurrence = input.toLowerCase().indexOf(query.toLowerCase());

        if (firstOccurrence < 0) return input;

        const before = input.slice(0, firstOccurrence);
        const match = input.slice(firstOccurrence, firstOccurrence + query.length);
        const after = input.slice(firstOccurrence + query.length);

        return `${before}<span class="highlighted">${match}</span>${func(after)}`;
    };
    return func;
};

export const prepareWordsModeHighlighter = query => {
    const func = input => {
        const words = query.split(' ').filter(w => w);

        const occurrences = [];
        for (const word of words) {
            // first possible occurrence position
            const position = input.toLowerCase().indexOf(word.toLowerCase());
            if (position >= 0) {
                occurrences.push({ word, position });
            }
        }

        if (occurrences.length === 0) return input;

        occurrences.sort((a, b) => (a.position > b.position ? 1 : -1));

        const { position: firstOccurrence, word: matchedWord } = occurrences[0];

        const before = input.slice(0, firstOccurrence);
        const match = input.slice(firstOccurrence, firstOccurrence + matchedWord.length);
        const after = input.slice(firstOccurrence + matchedWord.length);

        return `${before}<span class="highlighted">${match}</span>${func(after)}`;
    };
    return func;
};

export const prepareWordStartHighlighter = query => {
    return input => {
        const matchedWord = query.split(' ').find(word => word && input.toLowerCase().indexOf(word.toLowerCase()) === 0);

        if (!matchedWord) return input;

        const match = input.slice(0, matchedWord.length);
        const after = input.slice(matchedWord.length);

        return `<span class="highlighted">${match}</span>${after}`;
    };
};

export const prepareHTMLHighlighter = highlighterFunction => {
    const func = node => {
        if (node.children.length > 0) {
            for (const child of node.children) {
                func(child);
            }
        } else {
            node.innerHTML = highlighterFunction(node.innerText);
        }
    };
    return func;
};
