<template>
  <div class="bg-gray-100 border p-4">
    <div class="relative h-full overflow-hidden">
      <div class="text-sm font-frank font-bold leading-5 mb-2">select recipients</div>
      <RecipientInput :value="value" multiselect @input="handleInput" />

      <span class="form-hint form-error" v-if="error">
        {{ error }}
      </span>

      <div class="my-4 pb-2 border-b border-black" v-if="filterTags.length">
        <StatCounter :filter-tags="filterTags"/>
        <div class="flex justify-between mt-2">
          <div class="flex-grow"/>
          <button
            type="button"
            class="font-frank text-xs text-highlight-500"
            @click="handleClearTags"
          >clear all</button>
        </div>
      </div>

      <FilterTagList :value="value" @input="handleInput" />
    </div>
  </div>
</template>

<script>
import NotifyMixin from "@/mixins/NotifyMixin";
import FilterTagList from "@/components/msgs/FilterTagList";
import StatCounter from "@/components/msgs/StatCounter";
import RecipientInput from "@/components/msgs/RecipientInput";

export default {
  components: {RecipientInput, FilterTagList, StatCounter},
  mixins: [NotifyMixin],

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      required: false,
    },
  },
  
  data() {
    return {
      detailedView: false,
    };
  },

  emits: ['input'],

  computed: {
    filterTags() {
      return Object.values(this.value);
    },
  },

  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },

    handleClearTags() {
      this.$emit('input', {});
    },
  },
};
</script>

<style scoped>
</style>
