<template>
  <div v-if="show" class="absolute w-full h-full left-0 right-0 bg-white p-6 overflow-auto bg-gray-200">
    <CloseIcon class="absolute top-3 right-3 w-4 h-4 cursor-pointer" @click="$emit('update:show', false)"/>

    <div class="text-md font-frank font-500 mb-4">select a file to attach</div>

    <button @click="handleUploadClick" class="upload-button">
      <icon name="camera" class="w-4 h-4 mr-1"/>
      upload from computer
    </button>

    <div class="text-sm font-frank font-500 mb-4">add from gallery</div>
    <div v-if="mediaLibraryItems.length > 0" class="grid grid-cols-4 gap-4">
      <div
          v-for="item of mediaLibraryItems"
          :key="item.file_uuid"
          class="relative flex items-center justify-center h-32 cursor-pointer"
          :class="{'border border-gray-200': !isImage(item)}"
          @click="attachFromLibrary(item)"
      >
        <img v-if="isImage(item)" :src="item.thumbnail_url" alt="" class="w-full h-full object-cover"/>
        <span v-else class="break-all text-xs font-frank font-medium text-gray-700 text-center">{{item.original_name}}</span>
        <div class="absolute w-full h-full hover:bg-blue-600 hover:bg-opacity-25 "></div>
      </div>
    </div>
    <div v-else class="text-xs">
      gallery is empty
    </div>
    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AttachmentMixin from "@/mixins/AttachmentMixin";
import CloseIcon from "@/components/ui/icons/CloseIcon";
import Loader from "@/components/ui/Loader";
import Icon from "@/components/ui/Icon";

export default {
  name: "AttachmentSelectionPanel",
  components: {Icon, Loader, CloseIcon},
  mixins: [AttachmentMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:show',
  ],

  computed: {
    ...mapGetters({
      getLibraryFilesByType: 'files/getLibraryFilesByType',
    }),

    mediaLibraryItems() {
      return this.getLibraryFilesByType(this.acceptableMimeTypes);
    },
  },

  data() {
    return {
      loading: false,
      acceptableMimeTypes: [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ],
    };
  },

  methods: {
    isImage(item) {
      return item.file_type.split('/')?.[0] === 'image';
    },

    handleUploadClick() {
      this.attachFile({
        accept: this.acceptableMimeTypes,
      });
      this.$emit('update:show', false);
    },

    async attachFromLibrary(item) {
      this.loading = true;
      await this.attachFileManagerItem(item);
      this.$emit('update:show', false);
      this.loading = false;
    },
  },
}
</script>

<style scoped>
.upload-button {
  @apply inline-flex justify-center items-center h-10 px-6 mb-5;
  @apply border border-blue-300 bg-blue-100 text-blue-500 font-frank font-medium text-sm;
}
.upload-button:hover {
  @apply text-white bg-blue-500;
}
</style>
