<template>
  <FinalForm
      ref="form"
      :initial-values="filterValues"
      @change.self="handleChange"
  >
    <template v-slot="props">
      <div>
        <div class="flex justify-between items-center">
          <div class="flex flex-wrap items-center">
            <span class="font-frank font-400 mr-3">viewing:</span>
            <div
                v-for="(appliedFilter, index) of filtersToDisplay"
                :key="index"
                class="font-frank font-medium text-xs bg-blue-100 text-blue-700 py-1 mr-1"
            >
              <span class="px-3">{{ appliedFilter.text }}</span>
              <button
                  v-if="appliedFilter.clearable"
                  @click="clearFilterValue(appliedFilter.name, appliedFilter.value)"
                  class="cancel-filter"
              >&times;</button>
            </div>
          </div>
          <div class="flex items-center">
            <TextField
                name="searchQuery"
                placeholder="Search by message title or text"
            >
              <template v-slot:leading-icon>
                <Icon name="search2" class="w-4 h-4 text-graphite-800"/>
              </template>
            </TextField>
            <FilterIcon
                class="w-6 h-6 ml-3 cursor-pointer"
                :class="{'text-blue-700': expanded}"
                @click="expanded = !expanded"/>
          </div>
        </div>
        <div class="flex justify-end">
          <div
              class="filters-form bg-blue-50 border border-blue-100 p-5 mt-6"
              :class="{hidden: !expanded}"
          >
            <div class="flex items-end">
              <FinalField name="recipient">
                <template v-slot="filterProps">
                  <div class="filter flex flex-col">
                    <span class="text-xs leading-none font-600 text-black mb-2">recipient</span>
                    <RecipientInput
                      class="relative"
                      :value="filterProps.value"
                      @input="filterProps.change"
                    />
                </div>
                </template>
              </FinalField>
              <SelectInput
                  class="filter"
                  label="sender"
                  name="sender"
                  :options="senders"
              />
              <DateRangeInput
                  class="filter"
                  label="creation date"
                  name="createdAt"
                  :max-date="today"
                  position="right"
                  clear
              >
                <template v-slot:input="props">
                  <text-input v-if="!props.startDate && !props.endDate" value="all" />
                </template>
              </DateRangeInput>
              <button :disabled="!canReset" class="btn-primary" @click="props.reset">reset</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FinalForm>
</template>

<script>
import {FinalForm, FinalField} from 'vue-final-form';
import TextField from "@/components/form/TextField";
import FilterIcon from "@/components/ui/icons/FilterIcon";
import SelectInput from "@/components/form/SelectInput";
import DateRangeInput from "@/components/form/DateRangeInput";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import {mapActions, mapGetters} from "vuex";
import RecipientInput from "@/components/msgs/RecipientInput";
import TextInput from '@/components/ui/TextInput';

export default {
  name: "ListFiltersBlock",
  components: {
    RecipientInput,
    DateRangeInput,
    SelectInput,
    FilterIcon,
    TextField,
    FinalForm,
    FinalField,
    TextInput,
  },
  mixins: [CommunityTimezoneMixin],
  data() {
    return {
      expanded: false,
      today: new Date(),
      senders: [],
    };
  },
  computed: {
    ...mapGetters({
      filterValues: 'msgs/listFilterValues',
    }),
    filtersToDisplay() {
      const { sender, createdAt, recipient } = this.filterValues;
      return [
        {
          name: 'sender',
          value: sender,
          text: this.senders.find(o => o.key === sender)?.value,
          clearable: sender,
        },
        createdAt && {
          name: 'createdAt',
          value: createdAt,
          text: `${this.formatDateTime(createdAt.startDate, 'L', true)} - ${this.formatDateTime(createdAt.endDate, 'L', true)}`,
          clearable: true,
        },
        recipient && {
          name: 'recipient',
          value: recipient,
          text: (() => {
            switch (recipient.type) {
              case 'COMMUNITY':
                return `all ${recipient.communityName} contacts`;
              case 'BUILDING':
                return `building ${recipient.buildingName}`;
              case 'UNIT':
                return `unit ${recipient.unitName}, building ${recipient.buildingName}`;
              case 'PROFILE':
              case 'PERSON':
                return `${recipient.firstName} ${recipient.lastName}`;
              default:
                return '';
            }
          })(),
          clearable: true,
        },
      ].filter(f => f);
    },
    canReset() {
      return this.filtersToDisplay.filter(f => f.clearable).length > 0;
    }
  },
  methods: {
    ...mapActions({
      setFilterValues: 'msgs/setListFilterValues',
    }),
    handleChange(formState) {
      if (this.$refs.form && !formState.values.sender) {
        this.$refs.form.finalForm.change('sender', null);
      }

      this.setFilterValues(formState.values);
    },
    clearFilterValue(name, itemValue) {
      const {finalForm, formState} = this.$refs.form;

      const oldValue = formState.values[name];

      const newValue = Array.isArray(oldValue)
          ? oldValue.filter(value => value !== itemValue)
          : null;

      finalForm.change(name, newValue);
    },
  },
  mounted() {
    this.$msgsDataProvider.getList('quextUsers').then(response => {
      this.senders = [
        {key: null, value: 'all'},
        ...response.map(sender => ({key: sender.id, value: `${sender.firstName} ${sender.lastName}`})),
      ];
    });
  }
}
</script>

<style scoped>
.cancel-filter {
  @apply mr-2;
  margin-left: -0.25rem;
  color: #b6d2e6;
}


.filters-form {
  position: relative;
}

.filters-form:before,
.filters-form:after {
  position: absolute;
  content: "";
  bottom: 100%;
  border-color: transparent;
}

.filters-form:before {
  right: 3px;
  border-left-width: 8px;
  border-right-width: 8px;
  border-bottom: 1rem solid #eff8fe;
}

.filters-form:after {
  right: 4px;
  border-left-width: 7px;
  border-right-width: 7px;
  border-bottom: 14px solid #f7fcff;
}

.filters-form button {
  font-size: 0.6875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.filter {
  @apply ml-0 mr-6;
  width: 197px;
}
.filter ::v-deep(label),
.filter ::v-deep(.multiselect),
.filter ::v-deep(.multiselect__input),
.filter ::v-deep(.multiselect__single),
.filter ::v-deep(.multiselect__tags) {
  @apply text-xs leading-none min-h-0;
}
.filter ::v-deep(.multiselect__tags) {
  height: 2.375rem;
}
.filter ::v-deep(.search-dropdown) {
  @apply border-t-0;
  max-height: 30rem;
}
</style>
