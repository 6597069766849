import { sortBy } from 'lodash-es';

const order = ['COMMUNITY', 'BUILDING', 'UNIT', 'PERSON', 'PROFILE'];

export const sortFilterTags = tags => {
    return sortBy(tags, [
        tag => order.indexOf(tag.type),
        tag => {
            switch (tag.type) {
            case 'COMMUNITY':
                return tag.communityName;

            case 'BUILDING':
            case 'UNIT':
                return tag.buildingName;

            case 'PERSON':
            case 'PROFILE':
                return tag.lastName;
            }
        },
        tag => {
            switch (tag.type) {
            case 'UNIT':
                return tag.unitName;

            case 'PERSON':
            case 'PROFILE':
                return tag.firstName;
            }
        },
    ]);
};
