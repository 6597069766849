<template>
    <div class="h-full">
        <div
            v-if="topic"
            class="columns-wrapper text-black"
        >
            <div class="bg-gray-100 border p-4">
                <div class="text-xs font-frank font-bold mb-3">
                    {{ topic.unitsCount }} units / {{ topic.recipientsCount }} recipients
                </div>
                <FilterTagList
                    :value="filterTags"
                    :editable="false"
                />
            </div>
            <div class="flex flex-col">
                <div class="text-lg font-frank font-bold mb-5">
                    {{ topic.subject }}
                </div>
                <div class="flex items-baseline justify-between text-xs mb-8">
                    <div class="flex">
                        <div class="font-frank text-2xs mr-4">
                            sender:
                        </div>
                        <div>
                            <div class="font-bold">
                                {{ topic.messages[0].author.firstName }} {{ topic.messages[0].author.lastName }}
                            </div>
                            <div class="text-gray-500">
                                {{ topic.messages[0].author.role }}
                            </div>
                        </div>
                    </div>
                    <div class="flex text-gray-500">
                        <ClipIcon
                            v-if="topic.attachmentsNumber > 0"
                            class="transform rotate-45 mr-2"
                        />
                        <CommunityDateTimeDisplay
                            :input="topic.createdAt"
                            format="MM/DD/YYYY"
                        />
                    </div>
                </div>
                <div class="messaging-body">
                    <div
                        class="ql-editor"
                        v-html="messageBody"
                    />
                    <Attachments
                        service="MSGS"
                        class="mt-6"
                        :items="topic.messages[0].attachments"
                        readonly
                    />
                </div>
            </div>
        </div>
        <div v-else>
            no data
        </div>
        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalNavigation from '@/mixins/ModalNavigation';
import ClipIcon from '@/components/ui/icons/ClipIcon';
import NotifyMixin from '@/mixins/NotifyMixin';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import Loader from '@/components/ui/Loader';
import EventBus from '@/utils/EventBus';
import FilterTagList from '@/components/msgs/FilterTagList';
import { prepareHTMLHighlighter, prepareWordsModeHighlighter } from '@/utils/highlighting';
import Attachments from '@/components/ui/Attachments';

export default {
    components: { Attachments, FilterTagList, Loader, CommunityDateTimeDisplay, ClipIcon },

    mixins: [ModalNavigation, NotifyMixin],

    beforeRouteLeave(to, form, next) {
        if (to.name === 'msgs.duplicate') {
            this.saveToTmpBuffer(this.topic);
        }
        next();
    },

    data() {
        return {
            loading: false,
            topic: null,
        };
    },

    computed: {
        ...mapGetters({
            listFilters: 'msgs/listFilterValues',
        }),

        filterTags() {
            return this.topic.tags.filters.reduce((acc, cv) => ({ ...acc, [cv.id]: cv }), {});
        },

        prepareHighlightedHTML() {
            return prepareHTMLHighlighter(prepareWordsModeHighlighter(this.listFilters.searchQuery));
        },

        messageBody() {
            if (this.listFilters.searchQuery) {
                const el = document.createElement('span');
                el.innerHTML = this.topic.messages[0].body;
                this.prepareHighlightedHTML(el);
                return el.innerHTML;
            }
            return this.topic.messages[0].body;
        },
    },

    mounted() {
        this.loading = true;
        this.$msgsDataProvider
            .getOne('topics', { id: this.$route.params.messagingId })
            .then(response => {
                this.topic = response;
                this.setActiveModalBreadcrumbName(this.topic.subject);
            })
            .catch(error => this.notifyError(error.message))
            .finally(() => (this.loading = false));

        this.addAction({
            routeName: this.$route.name,
            item: {
                id: 'msgs-delete-topic',
                title: 'delete message',
                showConfirmation: true,
                confirmationType: 'warning',
                confirmationMessage:
                    'are you sure you want to delete the message? It will be deleted for all its recipients & Property staff. It can’t be restored.',
                confirmBtnText: 'delete',
                cancelBtnText: 'no, go back',
            },
        });

        EventBus.on('confirm-msgs-delete-topic', this.handleDeleteTopic);
    },

    beforeUnmount() {
        EventBus.off('confirm-msgs-delete-topic', this.handleDeleteTopic);
    },

    methods: {
        ...mapActions({
            addAction: 'actions/addAction',
            saveToTmpBuffer: 'msgs/saveToTmpBuffer',
        }),

        handleDeleteTopic() {
            this.loading = true;
            this.$msgsDataProvider
                .delete('topics', { id: this.$route.params.messagingId })
                .then(() => {
                    this.close();
                    this.notifySuccess('the message has been successfully deleted');
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => (this.loading = false));
        },
    },
};
</script>

<style scoped>
.columns-wrapper {
    @apply grid grid-rows-1 col-gap-6 w-full h-full;
    grid-template-columns: 1fr 2fr;
}

.tag {
    @apply inline-flex items-baseline mr-1 mb-1 p-2 bg-blue-100 text-blue-800 font-frank font-normal;
    font-size: 10px;
}

.messaging-body {
    @apply flex-grow overflow-auto;
}

.messaging-body .ql-editor {
    @apply min-h-0 h-auto;
    overflow-wrap: anywhere;
}

.messaging-body:deep(.highlighted) {
    background-color: yellow !important;
}
</style>
