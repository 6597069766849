<template>
    <FinalForm
        ref="form"
        class="h-full"
        :initial-values="initialValues"
        :submit="handleSubmit"
    >
        <template #default="formProps">
            <form
                class="h-full"
                @submit="formProps.handleSubmit"
            >
                <div class="columns-wrapper">
                    <FinalField
                        name="filter"
                        :validate="value => (Object.keys(value).length > 0 ? undefined : 'select items')"
                    >
                        <template #default="filterProps">
                            <RecipientPanel
                                :value="filterProps.value"
                                :error="
                                    ((filterProps.meta.error && filterProps.meta.touched) ||
                                        (filterProps.meta.submitError && !filterProps.meta.dirtySinceLastSubmit)) &&
                                        (filterProps.meta.error || filterProps.meta.submitError)
                                "
                                @input="filterProps.change"
                            />
                        </template>
                    </FinalField>
                    <div class="h-full flex flex-col border">
                        <FinalField
                            name="subject"
                            :validate="composeValidators(required, minLength(2), maxLength(100))"
                        >
                            <template #default="subjectProps">
                                <div class="border-b p-4">
                                    <div class="flex items-baseline font-frank text-sm">
                                        <label
                                            for="messaging-subject"
                                            class="mr-2"
                                        >subject:</label>
                                        <input
                                            id="messaging-subject"
                                            class="w-full"
                                            type="text"
                                            placeholder="enter the subject..."
                                            :value="subjectProps.value"
                                            :class="{ error: subjectProps.meta.error && subjectProps.meta.touched }"
                                            v-on="subjectProps.events"
                                        >
                                    </div>
                                    <span
                                        v-if="
                                            (subjectProps.meta.error && subjectProps.meta.touched) ||
                                                (subjectProps.meta.submitError && !subjectProps.meta.dirtySinceLastSubmit)
                                        "
                                        class="form-hint form-error"
                                    >
                                        {{ subjectProps.meta.error || subjectProps.meta.submitError }}
                                    </span>
                                </div>
                            </template>
                        </FinalField>
                        <FinalField
                            name="body"
                            :validate="composeValidators(required, minHMTLTextContentLength(5), maxHMTLTextContentLength(10000))"
                        >
                            <template #default="bodyProps">
                                <div class="flex flex-col flex-grow overflow-auto relative">
                                    <vue-editor
                                        ref="editor"
                                        class="messaging-body h-full"
                                        :editor-options="editorOptions"
                                        placeholder="type a message..."
                                        :value="bodyProps.value"
                                        @input.self="bodyProps.change"
                                        @focus="bodyProps.events.focus"
                                        @blur="bodyProps.events.blur"
                                    />
                                    <div class="hidden">
                                        <Icon
                                            ref="clipIcon"
                                            name="clip"
                                        />
                                        <div ref="attachments">
                                            <Attachments
                                                class="px-2 py-1"
                                                service="MSGS"
                                                :data-provider="$attachmentsDataProvider"
                                                :max-items="maxAttachedItems"
                                            />
                                            <div
                                                v-if="tooManyAttachments"
                                                class="form-hint form-error"
                                            >
                                                Must be no more than {{ maxAttachedItems }} attachments
                                            </div>
                                        </div>
                                    </div>

                                    <AttachmentSelectionPanel v-model:show="showAttachmentsSelector" />

                                    <span
                                        v-if="
                                            (bodyProps.meta.error && bodyProps.meta.touched) ||
                                                (bodyProps.meta.submitError && !bodyProps.meta.dirtySinceLastSubmit)
                                        "
                                        class="form-hint form-error"
                                    >
                                        {{ bodyProps.meta.error || bodyProps.meta.submitError }}
                                    </span>
                                </div>
                            </template>
                        </FinalField>
                    </div>
                </div>

                <div
                    id="modal-footer"
                    class="modal-footer"
                >
                    <div>
                        <button
                            type="button"
                            class="btn-primary btn-transparent"
                            @click="confirmCancel"
                        >
                            cancel
                        </button>
                    </div>
                    <div class="flex">
                        <button
                            class="btn-primary btn-solid"
                            :disabled="tooManyAttachments"
                        >
                            send
                        </button>
                    </div>
                </div>
                <loader
                    :loading="loading"
                    backdrop
                />
            </form>
        </template>
    </FinalForm>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import { VueEditor } from 'vue2-editor';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import { FinalForm, FinalField } from 'vue-final-form';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import RecipientPanel from '@/components/msgs/RecipientPanel';
import Icon from '@/components/ui/Icon';
import { mapActions, mapGetters } from 'vuex';
import Attachments from '@/components/ui/Attachments';
import AttachmentMixin from '@/mixins/AttachmentMixin';
import ModalFooterMixin from '@/components/ui/modals/ModalFooterMixin';
import AttachmentSelectionPanel from '@/components/msgs/AttachmentSelectionPanel';

export default {
    components: { AttachmentSelectionPanel, Attachments, Icon, Loader, VueEditor, FinalForm, FinalField, RecipientPanel },

    mixins: [ModalFooterMixin, ModalNavigation, ConfirmationMixin, NotifyMixin, ValidatorMixin, AttachmentMixin],

    beforeRouteEnter(to, from, next) {
        if (from.name === 'atts.gallery') {
            next(vm => {
                vm.prefillForm(vm.originalMessage);
                vm.resetTmpBuffer();
            });
        } else {
            next();
        }
    },

    beforeRouteLeave(to, from, next) {
        if (to.name === 'atts.gallery') {
            const { subject, body, filter } = this.$refs.form.formState.values;
            this.saveToTmpBuffer({
                subject,
                messages: [{ body }],
                tags: { filters: Object.values(filter) },
            });
        }
        next();
    },

    data(self) {
        return {
            loading: false,
            maxAttachedItems: 5,
            showAttachmentsSelector: false,
            editorOptions: {
                modules: {
                    toolbar: {
                        container: [
                            ['attach'],
                            [{ font: [false, 'monospace'] }],
                            [{ size: ['small', false, 'large', 'huge'] }],
                            ['bold', 'italic', 'underline'],
                            [{ align: [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            ['link'],
                            ['clean'],
                        ],

                        handlers: {
                            attach() {
                                self.showAttachmentsSelector = true;
                            },
                        },
                    },
                },
            },

            initialValues: {
                subject: '',
                body: '',
                filter: {},
            },

            disableSubmit: true,
        };
    },

    computed: {
        ...mapGetters({
            originalMessage: 'msgs/tmpBuffer',
            getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
        }),
    },

    watch: {
        attachingPossible(value) {
            this.getEditorToolbar().querySelector('.ql-attach').disabled = !value;
        },
    },

    async mounted() {
        this.$watch(
            () => this.$refs.form.formState.invalid,
            () => {
                this.disableSubmit = this.$refs.form.formState.invalid;
            }
        );

        const toolbar = this.getEditorToolbar();

        this.$refs.editor.$el.insertBefore(this.$refs.attachments, toolbar.nextSibling);

        const attachButton = toolbar.querySelector('.ql-attach');
        attachButton.appendChild(this.$refs.clipIcon.$el);
        attachButton.disabled = !this.attachingPossible;

        if (this.$route.name === 'msgs.duplicate') {
            this.loading = true;

            try {
                const source =
                    this.originalMessage || (await this.$msgsDataProvider.getOne('topics', { id: this.$route.params.messagingId }));
                this.prefillForm(source);
                this.resetTmpBuffer();
            } catch (e) {
                this.notifyError(e.message);
            }

            this.loading = false;
        }
    },

    methods: {
        ...mapActions({
            saveToTmpBuffer: 'msgs/saveToTmpBuffer',
            resetTmpBuffer: 'msgs/resetTmpBuffer',
        }),

        async confirmCancel() {
            if (
                await this.requestConfirmation({
                    confirmationType: 'warning',
                    confirmationMessage: 'are you sure you want to cancel message creation? You cannot save the message',
                    confirmBtnText: 'yes, cancel',
                    cancelBtnText: 'no, go back',
                })
            ) {
                this.close();
            }
        },

        async handleSubmit(values) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                await this.uploadFiles();

                await this.$msgsDataProvider.create('topics', {
                    data: {
                        ...values,
                        attachments: this.attachments.map(attach => ({ id: attach.id })),
                        filter: {
                            filters: Object.values(values.filter),
                        },
                    },
                });

                this.notifySuccess('messaging created');
                this.redirect('msgs.index');
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        submitMessage() {
            this.$refs.form.submit(this.$refs.form.formState.values);
        },

        prefillForm(data) {
            this.$refs.form.finalForm.change('subject', data.subject);
            this.$refs.form.finalForm.change('body', data.messages[0].body);
            this.$refs.form.finalForm.change(
                'filter',
                data.tags.filters.reduce((acc, cv) => ({ ...acc, [cv.id]: cv }), {})
            );
            this.disableSubmit = this.$refs.form.formState.invalid;
        },

        getEditorToolbar() {
            return this.$refs.editor.$el.querySelector('.ql-toolbar');
        },
    },
};
</script>

<style lang="postcss" scoped>
.columns-wrapper {
    @apply grid grid-rows-1 col-gap-4 w-full h-full;
    grid-template-columns: 1fr 2fr;
}

:deep(.messaging-body) {
    @apply flex flex-col-reverse flex-grow overflow-auto font-inter;

    .ql-container {
        @apply flex-grow overflow-auto font-inter border-0;
    }

    .ql-toolbar.ql-snow {
        @apply border-0 border-t font-inter bg-highlight-200;

        .ql-formats {
            @apply inline-flex items-center pr-1 mr-1 mb-2;

            &:not(:last-child) {
                @apply border-r border-highlight-300;
            }
        }

        /* Dropdowns */

        .ql-picker {
            @apply h-5 top-0;
        }

        .ql-picker-options {
            position: absolute;
            bottom: 18px;
            top: auto;
            z-index: 2;
        }

        .ql-picker-label {
            @apply inline-flex items-center text-xs pl-1 pr-0;
        }

        .ql-font,
        .ql-size,
        .ql-align {
            @apply w-auto;
        }

        .ql-font,
        .ql-size {
            svg {
                @apply relative mt-0 ml-2 top-auto right-auto;
            }
        }

        .ql-color-picker {
            @apply w-6 h-5;
        }

        .ql-color svg {
            @apply mt-1;
        }

        .ql-align {
            svg {
                @apply w-4 h-4;
                margin-top: 2px;
            }

            .ql-picker-label:after {
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="rgba(63,63,63,0.95)" d="M8 2.8L4 6.85h8zm0 10.4l-4-4h8z"/></svg>');
                @apply ml-1 mt-2;
            }
        }

        /* Buttons */

        button {
            @apply w-6 h-5;

            &.ql-bold,
            &.ql-italic {
                @apply w-5;
            }

            svg {
                @apply w-4 h-4;
            }
        }

        .ql-attach svg {
            padding: 2px 0 1px;
        }

        .ql-underline svg {
            @apply mt-px;
        }

        .ql-picker-item,
        .ql-picker-label,
        button:not(:disabled) {
            &.ql-selected,
            &.ql-active,
            &:focus,
            &:hover {
                color: inherit;
                background-color: rgba(0, 0, 0, 0.06);

                .ql-stroke {
                    stroke: #3f3f3f;
                }

                .ql-fill {
                    fill: #3f3f3f;
                }
            }
        }

        button:disabled {
            @apply text-gray-400;
        }
    }

    /* Editor placeholder */

    .ql-editor.ql-blank {
        &:before,
        &::before,
        :before,
        ::before {
            font-style: normal;
        }
    }
}
</style>
