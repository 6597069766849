<template>
    <div class="w-full h-full flex flex-col">
        <ListFiltersBlock />
        <div
            v-if="items.length"
            class="flex flex-col w-full h-full overflow-auto"
        >
            <div class="row header">
                <div>recipients</div>
                <div>message</div>
                <div>sender</div>
                <div class="text-right">
                    date
                </div>
            </div>
            <InfiniteScrollWrapper
                class="flex-grow overflow-auto"
                :loading="loading"
                @reachedTop="getPreviousPage"
                @reachedBottom="getNextPage"
            >
                <div
                    v-for="item of items"
                    :key="item.id"
                    class="row-wrapper cursor-pointer"
                    :class="{ expanded: item.id === expandedId }"
                    @click="handleRowClick(item.id)"
                >
                    <div
                        class="row"
                        :class="{ unread: item.isUnread }"
                    >
                        <div>
                            <div class="line-clamp-2 mb-1 text-black">
                                {{ getRecipientsText(item.tags.filters) }}
                            </div>
                            <div class="text-gray-500">
                                {{ item.unitsCount }} units / {{ item.recipientsCount }} recipients
                            </div>
                        </div>
                        <div>
                            <div class="line-clamp-2 text-gray-500">
                                <TextHighlighter
                                    :text="item.subject"
                                    :query="filterValues.searchQuery || ''"
                                    words-mode
                                    class="text-black"
                                />&puncsp;
                                <TextHighlighter
                                    :text="item.messagePreview"
                                    :query="filterValues.searchQuery || ''"
                                    words-mode
                                />
                            </div>
                        </div>
                        <div>
                            <div class="mb-1 text-black">
                                {{ item.author.firstName }} {{ item.author.lastName }}
                            </div>
                            <div class="text-gray-500">
                                {{ item.author.role }}
                            </div>
                        </div>
                        <div class="flex flex-row-reverse text-gray-500">
                            <CommunityDateTimeDisplay
                                :input="item.createdAt"
                                format="MM/DD/YY"
                                class="ml-2"
                            />
                            <ClipIcon
                                v-if="item.attachmentsNumber > 0"
                                class="transform rotate-45"
                            />
                        </div>
                    </div>
                    <div
                        v-if="item.id === expandedId"
                        class="links"
                    >
                        <router-link :to="{ name: 'msgs.details', params: { messagingId: item.id } }">
                            View Details
                        </router-link>
                        <!--<router-link to="" class="ml-4">Conversations</router-link>-->
                    </div>
                </div>
            </InfiniteScrollWrapper>
        </div>
        <div v-else>
            no data
        </div>
    </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import InfiniteScrollWrapper from '@/components/list/InfiniteScrollWrapper';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import ClipIcon from '@/components/ui/icons/ClipIcon';
import NotifyMixin from '@/mixins/NotifyMixin';
import ListFiltersBlock from '@/components/msgs/ListFiltersBlock';
import { mapGetters } from 'vuex';
import TextHighlighter from '@/components/ui/TextHighlighter';
import { sortFilterTags } from '@/utils/msgs';

const PAGE_SIZE = 20;
const MAX_ITEMS = 100;
const prepareListItem = ({ token, value }) => ({
    token,
    ...value,
    // To be replaced with real data from API somewhere in the future
    isUnread: false,
});

export default {
    components: { TextHighlighter, ListFiltersBlock, ClipIcon, CommunityDateTimeDisplay, InfiniteScrollWrapper },

    mixins: [ModalNavigation, NotifyMixin],

    data() {
        return {
            loading: false,
            hasPrevious: false,
            hasNext: true,
            items: [],
            expandedId: null,
        };
    },

    computed: {
        ...mapGetters({
            filterValues: 'msgs/listFilterValues',
        }),
    },

    watch: {
        filterValues() {
            this.initialize();
        },
    },

    mounted() {
        this.initialize();
    },

    methods: {
        handleRowClick(id) {
            this.expandedId = this.expandedId === id ? null : id;
        },

        getPreviousPage() {
            if (!this.hasPrevious) return;

            this.loading = true;

            this.$msgsDataProvider
                .getList('topics', this.makeRequestParameters(this.items[0].token, 'BACK'))
                .then(({ items }) => {
                    this.hasPrevious = items.length >= PAGE_SIZE;
                    this.items = [...items.map(prepareListItem).reverse(), ...this.items];

                    if (this.items.length > MAX_ITEMS) {
                        this.items = this.items.slice(0, -(this.items.length - MAX_ITEMS));
                        this.hasNext = true;
                    }
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => (this.loading = false));
        },

        getNextPage() {
            if (!this.hasNext) return;

            this.loading = true;

            this.$msgsDataProvider
                .getList('topics', this.makeRequestParameters(this.items[this.items.length - 1].token, 'FORWARD'))
                .then(({ items }) => {
                    this.hasNext = items.length >= PAGE_SIZE;
                    this.items = [...this.items, ...items.map(prepareListItem)];

                    if (this.items.length > MAX_ITEMS) {
                        this.items = this.items.slice(this.items.length - MAX_ITEMS);
                        this.hasPrevious = true;
                    }
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => (this.loading = false));
        },

        initialize() {
            this.hasPrevious = false;
            this.hasNext = true;
            this.loading = true;
            this.$msgsDataProvider
                .getList('topics', this.makeRequestParameters())
                .then(({ items }) => {
                    this.hasNext = items.length >= PAGE_SIZE;
                    this.items = items.map(prepareListItem);
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => (this.loading = false));
        },

        makeRequestParameters(token, dir) {
            const params = {
                dir,
                token,
                limit: PAGE_SIZE,
            };

            if (this.filterValues.createdAt) {
                params.creationDateFrom = this.filterValues.createdAt.startDate;
                params.creationDateTo = this.filterValues.createdAt.endDate;
            }

            if (this.filterValues.sender) {
                params.sender = this.filterValues.sender;
            }

            if (this.filterValues.recipient) {
                params.recipient = this.filterValues.recipient.id;
            }

            if (this.filterValues.searchQuery) {
                params.q = this.filterValues.searchQuery;
            }

            return params;
        },

        getRecipientsText(tags) {
            if (tags.find(({ type }) => type === 'COMMUNITY')) return 'All contacts';

            const sorted = sortFilterTags(tags.filter(({ type }) => type !== 'COMMUNITY'));

            return [
                { prefix: 'Building: ', items: sorted.filter(({ type }) => type === 'BUILDING').map(({ buildingName }) => buildingName) },
                { prefix: 'Unit: ', items: sorted.filter(({ type }) => type === 'UNIT').map(({ unitName }) => unitName) },
                {
                    prefix: '',
                    items: sorted
                        .filter(({ type }) => type === 'PERSON' || type === 'PROFILE')
                        .map(({ firstName, lastName }) => `${firstName} ${lastName}`),
                },
            ]
                .filter(({ items }) => items.length > 0)
                .map(({ prefix, items }) => prefix + items.join(', '))
                .join('; ');
        },
    },
};
</script>

<style scoped>
.row {
    @apply grid col-gap-4;
    grid-template-columns: 2fr 3fr 1.5fr 1fr;
}
.row > * {
    @apply p-4 text-xs;
}
.row.unread > * > *:first-child {
    @apply font-bold text-black;
}
.row-wrapper:nth-child(even) {
    @apply bg-gray-100;
}

.header {
    @apply border-b border-black font-frank font-medium;
}
.header > * {
    @apply text-black;
    padding-bottom: 10px;
    font-size: 11px;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
}

.links {
    @apply text-blue-500 underline px-4 pb-4;
    font-size: 10px;
}

.row-wrapper.expanded {
    @apply bg-blue-100 border border-blue-300;
}
</style>
