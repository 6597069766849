<template>
  <div
    ref="container"
    class="flex"
    :class="{'with-images': withImages, 'with-filenames': !withImages}"
  >
    <div
      v-if="addButtonVisible"
      class="attach-item attach-add"
      :class="{'with-items': attachments.length > 0}"
      @click="handleAttach"
    >
      <div v-if="attachments.length === 0" class="flex items-center">
        <Icon :name="buttonIcon" class="w-4 h-4 mr-2"/>
        {{buttonLabel}}
      </div>
      <Icon v-else name="add"/>
    </div>
    <div
      v-for="(attach, index) in attachments"
      :key="attach.id"
      class="attach-item"
      :class="{hidden: index + 1 > attachmentLimit, error: !!attach.error}"
    >
      <a class="flex items-center" href="#" @click.prevent="handleOpen(attach)" :title="attach.filename">
        <img
          v-if="withImages && attach.isImage"
          class="attach-preview"
          :class="{'opacity-25': !!attach.inProgress}"
          :src="attach.previewUrl"
          :alt="attach.filename"
        />
        <div v-else-if="withImages" class="attach-preview bg-blue-100 flex items-center justify-center flex-col">
          <FileTextIcon class="w-8 h-8 mt-3"/>
          <span class="text-2xs font-semibold">{{getExtension(attach)}}</span>
        </div>
        <SpinLoader class="spin-loader" :loading="!!attach.inProgress" />
        <span v-if="!withImages">{{attach.filename}}</span>
      </a>
      <Icon
        v-if="!attach.stored && !readonly"
        name="close"
        class="attach-remove"
        @click="removeAttachment(attach.id)"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Icon from "@/components/ui/Icon";
import SpinLoader from "@/components/ui/SpinLoader";
import AttachmentMixin from "@/mixins/AttachmentMixin";
import FileTextIcon from "@/components/ui/icons/FileTextIcon";

export default {
  components: {FileTextIcon, SpinLoader, Icon},

  mixins: [AttachmentMixin],

  props: {
    /**
     * Data provider to fetch upload/download URLs. `$attachmentsDataProvider` is used default.
     */
    dataProvider: {
      type: Object,
      required: false,
    },

    /**
     * Name of resource used by the data provider. May be needed when custom data provider is used.
     */
    resource: {
      type: String,
      default: 'attachments',
    },

    /**
     * Owner service code (e.g. `'MSGS'`, `'SREQ'`, etc.)
     */
    service: {
      type: String,
      required: true,
    },

    /**
     * Maximum number of attachments allowed
     */
    maxItems: {
      type: Number,
      default: 0,
    },

    /**
     * Array of strings representing acceptable mime types
     */
    accept: {
      type: Array,
      required: false,
    },

    /**
     * Initial array of attachments
     */
    items: {
      type: Array,
      required: false,
    },

    /**
     * Should image previews be shown instead of filenames
     */
    withImages: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      defaul: false,
    },

    /**
     * Button label name.
     */
    buttonLabel: {
      type: String,
      default: 'add images',
    },

    /**
     * Button icon name.
     */
    buttonIcon: {
      type: String,
      default: 'camera2',
    },
  },

  emits: ['overflow'],

  data() {
    return {
      attachmentLimit: Infinity,
    };
  },

  computed: {
    addButtonVisible() {
      return this.withImages && !this.readonly && this.attachingPossible;
    },

    overflow() {
      return this.attachments.length > this.attachmentLimit;
    },
  },

  watch: {
    overflow(value) {
      /**
       * Some of attachments are hidden due to lack of space or all of items are shown again
       *
       * @property {Boolean} value there are hidden items or not
       */
      this.$emit('overflow', value);
    },
  },

  methods: {
    ...mapActions({
      removeAttachment: 'attachments/removeAttachment',
    }),

    handleAttach() {
      this.attachFile({
        accept: this.accept,
      });
    },

    handleOpen(attach) {
      this.$router.push({ name: 'atts.gallery', query: { openOn: attach.id } });
    },

    handleResize() {
      if (!this.withImages || !this.$refs.container) {
        return;
      }

      this.attachmentLimit = Math.floor(this.$refs.container.clientWidth / 84) - (this.readonly ? 0 : 1);
    },

    getExtension(attach) {
      return attach.filename.split('.').pop();
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 1000);

    this.initAttachments(this.items, {
      maxItems: this.maxItems,
      ownerRoute: this.$route.name,
      ownerService: this.service,
      dataProvider: this.dataProvider,
      resource: this.resource,
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.with-images {
  > .attach-item {
    @apply relative mb-2 mr-2;

    .attach-preview {
      @apply object-cover;
      width: 76px;
      height: 76px;
    }

    .attach-remove {
      @apply hidden absolute z-10 rounded-3xl w-5 h-5 p-1 bg-white border border-blue-500 text-blue-500 cursor-pointer;
      top: -4px;
      right: -4px;
    }

    &.attach-add {
      @apply flex justify-center items-center border border-blue-500 text-blue-500 font-frank font-medium text-2sm cursor-pointer;

      &:not(.with-items) {
        @apply h-10 px-6;
      }

      &.with-items {
        width: 76px;
        height: 76px;
      }

      &:hover {
        @apply text-active-600 border-active-600;
      }
    }

    &:hover {
      &:not(.attach-add) {
        @apply bg-blue-500;
      }

      .attach-preview {
        @apply opacity-50;
      }

      .attach-remove {
        @apply block;

        &:hover {
          @apply bg-blue-200;
        }
      }
    }

    &.error {}
  }

  .spin-loader {
    @apply absolute w-6 h-6;
    left: 26px;
    top: 26px;
  }
}

.with-filenames {
  @apply flex-wrap;

  > .attach-item {
    @apply flex items-center mb-1 mr-1 px-2 py-1 bg-blue-100 text-blue-800 font-frank font-normal text-xs leading-4;

    .attach-remove {
      @apply flex-shrink-0 ml-2 cursor-pointer w-2 h-2;
      @apply text-blue-400;

      &:hover {
        @apply text-blue-900;
      }
    }

    &.error {
      @apply bg-red-100 text-red-800;

      .attach-remove {
        @apply text-red-500;

        &:hover {
          @apply text-red-900;
        }
      }
    }
  }

  .spin-loader {
    @apply w-3 h-3 mr-2;
  }
}
</style>
