<template>
  <div>
    <div class="filter-tag" v-for="tag in sortedTags" :key="tag.id">
      <div v-if="tag.type === 'COMMUNITY'">
        all {{tag.communityName}} contacts
      </div>

      <div v-if="tag.type === 'BUILDING'">
        building {{tag.buildingName}}
      </div>

      <div v-if="tag.type === 'UNIT'">
        unit {{tag.unitName}}, building {{tag.buildingName}}
      </div>

      <div v-if="tag.type === 'PERSON'">
        <div><span class="font-bold">{{tag.firstName}} {{tag.lastName}},</span> {{tag.email}}</div>
        <div>all {{tag.profiles.length}} units</div>
      </div>

      <div v-if="tag.type === 'PROFILE'">
        <div><span class="font-bold">{{tag.firstName}} {{tag.lastName}},</span> {{tag.email}}</div>
        <div>unit {{tag.unitName}}, building {{tag.buildingName}}</div>
      </div>

      <Icon
        v-if="editable"
        name="close"
        class="flex-shrink-0 ml-2 text-blue-400 cursor-pointer w-2 h-2"
        @click="handleRemoveTag(tag)"
      />
    </div>
  </div>
</template>

<script>
import {omit} from "lodash-es";
import Icon from "@/components/ui/Icon";
import {sortFilterTags} from "@/utils/msgs";

export default {
  components: {Icon},

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    editable: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['input'],

  computed: {
    sortedTags() {
      return sortFilterTags(Object.values(this.value));
    },
  },

  methods: {
    handleRemoveTag(tag) {
      this.$emit('input', omit(this.value, tag.id));
    },
  },
};
</script>

<style scoped>
.filter-tag {
  @apply inline-flex items-center mb-1 mr-1 p-2 bg-blue-100 text-blue-800 font-frank font-normal;
  font-size: 10px;
}
</style>
