<template>
  <div ref="searchBox">
    <label class="relative block">
      <text-input
        :placeholder="(multiselect || opened) ? 'search by name, email, unit, building' : ''"
        v-model="query"
        @focus="handleFocus"
      />
      <span
        class="absolute top-0 left-0 w-full h-full p-3 overflow-ellipsis flex items-center text-sm"
        v-if="!multiselect && !opened"
      >
        <span class="whitespace-no-wrap overflow-hidden overflow-ellipsis" :title="singularValueText">
          {{ singularValueText }}
        </span>
      </span>
    </label>
    <div class="search-dropdown" v-if="opened && !loading">
      <div v-if="foundGroups.length === 0" class="px-4 py-2 text-xs text-gray-500">no results</div>

      <div v-else-if="selectedPerson" class="search-group">
        <SearchItem
          :item="selectedPerson"
          back-link
          @click="selectedPerson = null"
        />
        <SearchItem
          :item="selectedPerson"
          person-details
          :selected="isSelected(selectedPerson)"
          @click="clickTag"
        />
        <SearchItem
          v-for="item in selectedPerson.profiles"
          :key="item.id"
          :item="item"
          person-details
          :selected="isSelected(item)"
          @click="clickTag"
        />
      </div>

      <div v-else class="search-group" v-for="group in foundGroups" :key="group.key">
        <div class="px-4 py-2 text-gray-500" v-if="group.title">{{group.title}}</div>
        <SearchItem
          v-for="item in group.items"
          :key="item.id"
          :item="item"
          :search-query="query"
          :selected="isSelected(item)"
          :with-details="multiselect"
          @click="clickTag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotifyMixin from "@/mixins/NotifyMixin";
import SearchItem from "@/components/msgs/SearchItem";
import {debounce, omit} from "lodash-es";
import TextInput from '@/components/ui/TextInput';

const filterGroupsAll = [
  { key: 'communities', title: null },
  { key: 'buildings', title: 'buildings' },
  { key: 'units', title: 'units' },
  { key: 'persons', title: 'residents' },
];

const filterGroupsPersons = [
  { key: 'persons', title: null },
];

export default {
  components: {SearchItem, TextInput},

  mixins: [NotifyMixin],

  props: {
    value: {
      type: Object,
    },

    multiselect: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input'],

  data() {
    return {
      loading: false,
      query: '',
      opened: false,
      foundItems: {},
      selectedPerson: null,
    };
  },

  computed: {
    foundGroups() {
      return (this.multiselect
        ? filterGroupsAll
        : filterGroupsPersons
      ).reduce((acc, group) => {
        const items = this.foundItems[group.key];

        if (items && items.length) {
          acc.push({
            ...group,
            items,
          });
        }

        return acc;
      }, []);
    },

    singularValueText() {
      if (this.multiselect) return '';

      if (!this.value) return 'all';

      switch (this.value.type) {
        case 'COMMUNITY':
          return `all ${this.value.communityName} contacts`;
        case 'BUILDING':
          return `building ${this.value.buildingName}`;
        case 'UNIT':
          return `unit ${this.value.unitName}, building ${this.value.buildingName}`;
        case 'PROFILE':
        case 'PERSON':
          return `${this.value.firstName} ${this.value.lastName}`;
      }

      return '';
    }
  },

  watch: {
    query: debounce(function () {
      this.searchTags();
    }, 500),

    opened(value) {
      if (!value) {
        if (!this.multiselect) {
          this.query = '';
        }
        return;
      }

      this.searchTags();
    },
  },

  methods: {
    searchTags() {
      this.selectedPerson = null;
      this.loading = true;
      this.$msgsDataProvider.search('filterTags', {q: this.query})
        .then(res => {
          if (this.multiselect) {
            this.foundItems = {
              ...res,
              persons: res.persons.map(person => person.profiles.length === 1
                ? person.profiles[0]
                : person
              ),
            };
          } else {
            this.foundItems = res;

            if (this.value && !this.foundItems.persons.find(person => person.id === this.value.id)) {
              this.foundItems.persons.unshift(this.value);
            }
          }
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => {
          this.loading = false;
        });
    },

    isSelected(tag) {
      if (this.multiselect) {
        return !!(this.value && this.value[tag.id]);
      } else {
        return this.value === tag || this.value?.id === tag.id;
      }
    },

    clickTag(tag) {
      if (this.multiselect && tag.type === 'PERSON' && !this.selectedPerson) {
        this.selectedPerson = tag;
        return;
      }

      this.query = '';
      this.opened = false;
      this.selectedPerson = null;

      if (this.isSelected(tag)) {
        this.$emit('input', this.multiselect ? omit(this.value, tag.id) : null);
      } else {
        this.$emit('input', this.multiselect ? {...this.value, [tag.id]: tag} : tag);
      }
    },

    handleFocus() {
      this.opened = true;
    },

    handleMouseDown(event) {
      if (!this.$refs.searchBox.contains(event.target)) {
        this.opened = false;
        this.selectedPerson = null;
      }
    },
  },

  mounted() {
    window.addEventListener('mousedown', this.handleMouseDown);
  },

  beforeUnmount() {
    window.removeEventListener('mousedown', this.handleMouseDown);
  },
};
</script>

<style scoped>
.search-dropdown {
  @apply absolute w-full border border-t-0 bg-white overflow-x-hidden overflow-y-auto;
  max-height: calc(100% - 4.25rem);
  top: auto;
}

.search-group {
  @apply py-2 text-xs;
}

.search-group + .search-group {
  @apply border-t;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>
