<template>
  <div class="font-frank font-bold text-xs">{{units}} {{$tc('app.unit', units)}} / {{recipients}} {{$tc('app.recipient', recipients)}}</div>
</template>

<script>
import NotifyMixin from "@/mixins/NotifyMixin";

export default {
  mixins: [NotifyMixin],

  props: {
    filterTags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      units: 0,
      recipients: 0,
    };
  },

  watch: {
    filterTags: {
      immediate: true,
      handler() {
        this.$msgsDataProvider.calculateStat('filterTags', {
          data: {
            filters: this.filterTags,
          },
        })
          .then(res => {
            this.units = res.unitsCount;
            this.recipients = res.recipientsCount;
          })
          .catch(error => this.notifyError(error.message));
      },
    },
  },
};
</script>
